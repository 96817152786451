var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Meta'),_vm._v(" "),(!/:\/\/framagenda.org\/apps\/registration/.test(_vm.$t('url')))?_c('sidebar-menu'):_vm._e(),_vm._v(" "),_c('alert'),_vm._v(" "),(_vm.is.after('2024/6/27') && _vm.is.before('2024/7/10')
      && (/\/abc\//.test(_vm.$t('url'))
      || /mypads\/\?\/mypads$/.test(_vm.$t('url'))
      || /framadate.org\/.+\/admin/.test(_vm.$t('url'))
      || /framaforms.org\/(node|user)\//.test(_vm.$t('url'))
      || /framindmap.org\/c\/maps\/$/.test(_vm.$t('url'))
      || /framagames.org/.test(_vm.$t('url'))))?_c('modal-dorlotons'):_vm._e(),_vm._v(" "),_c('modal-info'),_vm._v(" "),(/:\/\/framateam.org/.test(_vm.$t('url')))?_c('framateam'):_vm._e(),_vm._v(" "),_c('footer-menu'),_vm._v(" "),_c('back-top')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }