<template>
  <div
    id="f-alert"
    style="display: none;"
  >
    <b-alert
      v-if="config[0] !== '' && state.alert"
      :show="state.alert"
      :variant="config[0]"
      dismissible
      :dismiss-label="$t('txt.close')"
      @dismissed="state.alert = false; cookie('w', 'f-alert', true, 604800000);"
    >
      <div
        class="text-center"
        v-html="config[1]"
      ></div>
    </b-alert>
  </div>
</template>

<script>
export default {
  data() {
    const config = ['', ''];
    /* Test config */
    if (/\/test\/verbose.*\.html$/.test(this.$t('url'))) {
      config[0] = 'info';
      config[1] = this.$t('alert.test');
    }

    /* Sites config */
    if (/(dvd|key|start|zic)/.test(this.$t('site'))) {
      config[0] = 'warning';
      config[1] = this.$t('alert.archives', {
        site: this.$t(`color.${this.$t('site')}`),
      });
    }

    switch (this.$t('site')) {
      /* case 'agenda':
        if (this.is.after('2019/05/21') && this.is.before('2019/05/28')) {
          config[0] = 'warning';
          config[1] = this.$t('alert.agenda');
        }
        break; */
      case 'archives.book':
        config[0] = 'info';
        config[1] = this.$t('alert.book');
        break;
      case 'book':
        config[0] = 'info';
        config[1] = this.$t('alert.book');
        break;
        /* case 'date':
        if (this.is.before('2020/12/08')) {
          config[0] = 'warning';
          config[1] = this.$t('alert.date');
        }
        break; */
        /* case 'mindmap':
        if (this.is.before('2023/10/19')) {
          config[0] = 'warning';
          config[1] = this.$t('alert.mindmap');
        }
        break; */
      default:
        // no-default
        break;
    }

    return {
      config,
      /** [color (from bootstrap), text, cookie name, cookie duration] */
      state: {
        alert: !this.cookie('r', 'f-alert'),
      },
    };
  },

  created() {
    if (this.config[0] !== '') {
      // info -> portal -> main
      this.$parent.$parent.busy = true;
    }
  },
};
</script>
