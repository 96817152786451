<template>
  <b-modal
    id="f-modal-dorlotons"
    v-model="state.dorlotons"
    :static="true"
    :lazy="true"
    :title="$t('modal.dorlotons.title')"
    :header-close-label="$t('txt.close')"
    :cancel-title-html="$t('txt.close')"
    cancel-variant=" d-none"
    ok-variant=" d-none"
    :ok-title-html="$t('txt.nevershow')"
  >
    <div v-html="$t('modal.dorlotons.md')"></div>
    <p class="text-center my-4">
      <a
        class="btn btn-soutenir"
        :href="`${$t('link.sdio')}?modal&mtm_campaign=Dorlotons&mtm_source=Framanav&mtm_placement=Modal`"
        target="_blank"
        @click="$bvModal.hide('f-modal-dorlotons')"
      >
        <i class="fas fa-heart"></i>
        <strong v-html="$t('modal.dorlotons.btn', { days, s })"></strong>
      </a>
    </p>
    <p
      class="small text-muted m-0"
      v-html="$t('modal.dorlotons.help')"
    ></p>
  </b-modal>
</template>

<script>
export default {
  data() {
    const days = (((new Date('7/4/2024')).getTime() - (new Date()).getTime()) / (1000 * 3600 * 24) + 0.5).toFixed(0);
    return {
      days,
      s: days > 1 ? 's' : '',
      state: {
        dorlotons: !this.cookie('r', 'modal-dorlotons'),
      },
    };
  },

  mounted() {
    this.cookie('w', 'modal-dorlotons', true, 604800000);
  },
};
</script>

<style lang="scss">
#f-modal-dorlotons[style*="block"] {
  display: flex !important;
}

/* No modal at all */
/* ------------------------------------------------------------------ */
[data-inframe="true"] {
  #f-modal-dorlotons[style],
  #f-modal-dorlotons[style] + .modal-backdrop {
    display: none !important;
  }

  .modal-open {
    overflow: auto;
  }
}

#f-- #f-modal-dorlotons {
  align-items: center;
  padding-right: 0px;

  .close {
    color: #fff;
    text-shadow: none;
  }

  .modal-content {
    background: url('../../../public/img/bg-clouds.jpg');
    background-size: cover;
    border: none !important;
    border-radius: 0 !important;
  }

  .modal-body {
    background: #ffffffcc;
    border-radius: .5rem;
    margin: 0 2.5rem .5rem;
  }

  .modal-header,
  .modal-footer {
    border-color: transparent !important;
  }

  .modal-title { /* .sr-only */
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
  }
}
</style>
