<template>
  <div
    id="f-alert-dorlotons"
    style="display: none;"
  >
    <b-alert
      v-if="state.alert"
      :show="state.alert"
      variant="dorlotons"
      dismissible
      :dismiss-label="$t('txt.close')"
      @dismissed="state.alert = false; cookie('w', 'f-alert-dorlotons', true, 604800000);"
    >
      <b-row
        v-if="is.after('2024/6/13') && is.before('2024/6/20')"
        class="flex-column mt-2"
      >
        <b-col
          class="d-flex"
        >
          <div class="dorlotons-message" v-html="$t('alert.dorlotons1.md')"></div>
        </b-col>
        <b-col class="text-right mt-2 pr-4">
          <a
            class="btn btn-sm btn-outline-warning"
            :href="`${$t('link.sdio')}?alert1&mtm_campaign=Dorlotons&mtm_source=Framanav&mtm_placement=Alert1`"
          >
            <strong v-html="$t('alert.dorlotons1.btn')"></strong>
            <i class="fas fa-chevron-right"></i>
          </a>
        </b-col>
      </b-row>
      <b-row
        v-if="is.after('2024/6/20') && is.before('2024/6/27')"
        class="flex-column mt-2"
      >
        <b-col
          class="d-flex"
        >
          <div class="dorlotons-message" v-html="$t('alert.dorlotons2.md')"></div>
        </b-col>
        <b-col class="text-right mt-2 pr-4">
          <a
            class="btn btn-sm btn-outline-warning"
            :href="`${$t('link.sdio')}?alert2&mtm_campaign=Dorlotons&mtm_source=Framanav&mtm_placement=Alert2`"
          >
            <strong v-html="$t('alert.dorlotons2.btn')"></strong>
            <i class="fas fa-chevron-right"></i>
          </a>
        </b-col>
      </b-row>
      <b-row
        v-if="is.after('2024/6/27') && is.before('2024/7/3')"
        class="flex-column mt-2"
      >
        <b-col
          class="d-flex"
        >
          <div class="dorlotons-message" v-html="$t('alert.dorlotons3.md')"></div>
        </b-col>
        <b-col class="text-right mt-2 pr-4">
          <a
            class="btn btn-sm btn-outline-warning"
            :href="`${$t('link.sdio')}?alert3&mtm_campaign=Dorlotons&mtm_source=Framanav&mtm_placement=Alert3`"
          >
            <strong v-html="$t('alert.dorlotons3.btn')"></strong>
            <i class="fas fa-chevron-right"></i>
          </a>
        </b-col>
      </b-row>
      <b-row
        v-if="is.after('2024/7/3') && is.before('2024/7/10')"
        class="flex-column mt-2"
      >
        <b-col
          class="d-flex"
        >
          <div v-if="days > 1" class="dorlotons-message" v-html="$t('alert.dorlotons4.md', { days, s: sDays })"></div>
          <div v-else-if="hours > 0" class="dorlotons-message" v-html="$t('alert.dorlotons4.md2', { hours, s: sHours })"></div>
        </b-col>
        <b-col class="text-right mt-2 pr-4">
          <a
            class="btn btn-sm btn-outline-warning"
            :href="`${$t('link.sdio')}?alert4&mtm_campaign=Dorlotons&mtm_source=Framanav&mtm_placement=Alert4`"
          >
            <strong v-html="$t('alert.dorlotons4.btn')"></strong>
            <i class="fas fa-chevron-right"></i>
          </a>
        </b-col>
      </b-row>
    </b-alert>
  </div>
</template>

<script>
export default {
  data() {
    /*
      S1: 13/06/2024 -> 20/06/2024
      S2: 20/06/2024 -> 27/06/2024
      S3: 27/06/2024 -> 04/07/2024
      S4: 04/07/2024 -> 10/07/2024
    */
    const end = (new Date('7/10/2024')).getTime();
    const now = (new Date()).getTime();
    const days = ((end - now) / (1000 * 3600 * 24) + 0.5).toFixed(0);
    const hours = ((end - now) / (1000 * 3600) + 0.5).toFixed(0);
    return {
      days,
      hours,
      sDays: days > 1 ? 's' : '',
      sHours: hours > 1 ? 's' : '',
      state: {
        alert: !this.cookie('r', 'f-alert-dorlotons'),
      },
    };
  },
};
</script>

<style lang="scss">
#f-- #fp-alert .alert-dorlotons {
  --f-i9: #0b1c54;
  background: var(--f-i9) url('../../../public/img/bg-cloud-news.jpg') no-repeat 0 100%;
  border: 6px solid var(--f-i9);
  padding: 0.75rem 0 0.5rem 0;
  background-size: cover;
  color: #fff;

  .close {
    color: var(--f-i9);
    padding: .25rem 0;
  }

  div {
    h1 {
      text-align: left;
      background: var(--f-i9);
      color: #fff;
      display: inline;
      font-family: 'Tovari Sans';
      font-size: 1.75rem;
      line-height: 1.75rem;
      margin: 0;
      padding: .5rem .75rem .5rem .5rem;
    }

    p {
      color: #ffffff;
      background: #0b1c54bb;
      padding: .5rem .75rem;
      margin: 0;
      max-width: calc(7 / 12 * 100%);
    }

    .btn-outline-warning {
      border: none;
      background: #fff;
      color: var(--f-o7);

      i::before {
        color: var(--f-o7);
      }

      &:hover, &:focus, &:active {
        color: #fff;
        background: var(--f-o7);

        i::before {
          color: #fff;
        }
      }
    }
  }
}

[data-url*="://framasoft.org"],
[data-url*=".frama.io"]{
  #fp-alert {
    display: none !important;
  }
}

.dorlotons-message {
  max-width: 95vw;
}
</style>
